import React, {useEffect, useState} from "react";

import API from "../../Generic/API";
import Formatter from "../../Style/Formatter";
import StatusLogIndicator from "../../Components/StatusLogIndicator";
import DownloadCsv from "../../Generic/DownloadCsv";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Link } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import DialogContentText from "@mui/material/DialogContentText";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import MUIDataTable from "mui-datatables";


/**
 * OverdueAlerts component.
 *
 * @returns {*}
 * @constructor
 */
function OverdueAlerts() {
    const [isLoading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [target, setTarget] = useState({});
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);

    /**
     * Loads all records.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);
        const response = await API.send('pay-at-close/overdue-alerts');

        let responseArray = Object.entries(response).map((key) => key[1]);

        responseArray.forEach((element, i) => {
            responseArray[i].message = getAlertMessage(element);
        });

        setRecords(responseArray);

        setLoading(false);
    };


    /**
     * Opens the confirmation dialogue.
     *
     * @param target
     */
    const handleConfirmationOpen = (target) => {
        setTarget(target);
        setConfirmationOpen(true);
    };


    /**
     * Closes the confirmation dialogue.
     */
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };


    /**
     * Returns the appropriate record alert message.
     *
     * @param props
     * @returns {string}
     */
    const getAlertMessage = (record) => {
        const today = new Date();
        const orderDate = new Date(record.order_datetime);
        const differenceInDays = (today - orderDate) / (1000 * 60 * 60 * 24);

        return 'This inspection is ' + Math.floor(differenceInDays) + ' days old.'
    };


    /**
     * Handles the resolve action against the target record.
     *
     * @returns {Promise<void>}
     */
    const doResolve = async () => {
        setRecords([]);
        setLoading(true);

        await API.send(`pay-at-close/transaction/${target.id}/resolve-overdue`, 'POST');

        setConfirmationOpen(false);
        getResults();
    };


    /**
     * Load all records on initial mount.
     */
    useEffect(() => {
        getResults().then(() => true);
    }, []);

    return (
        <Paper className={'table__block'}>
            <div className={'block__heading block__heading--bordered'}>
                <div className={'columns__1'}>
                    <h3 className={'text__bold'}>Overdue Alerts</h3>
                    <div className={'d-flex__justify'}>
                        <span className={'text__muted'}>The following transactions are currently overdue and require attention.</span>

                        <IconButton
                            onClick={() => {
                                document.querySelectorAll('*[data-testid="DownloadCSV-iconButton"]')[1].click();
                            }}
                            style={{marginTop: '0.3em'}}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
            <TableContainer component={'div'} className={'transactions__list'}>
                <MUIDataTable
                    className={'table__grid'}
                    data={records}
                    options={
                        {
                            tableBodyHeight: "500px",
                            selectableRows: "none",
                            responsive: 'standard',
                            filter: false,
                            search: false,
                            print: false,
                            download: true,
                            viewColumns: true,
                            customToolbar: null,
                            fixedHeader: true,
                            rowsPerPage: 1000,
                            downloadOptions: {
                                filename: 'overdue-alerts.csv'
                            },
                            customFooter: () => {
                                return '';
                            },
                            customRowRender: (data, id) => {
                                const record = records[id];

                                return (
                                    <TableRow key={`overdue-alert-${record.id}`}>
                                        <TableCell>
                                            <Link to={`/transaction/${record.id}`}>
                                                <StatusLogIndicator status={record.status}/>
                                                {record.order_full_address}
                                            </Link>
                                            <div>
                                                OID: {record.order_number}, CK: {record.companykey}
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            {record.message}
                                        </TableCell>
                                        <TableCell>
                                            {Formatter.date(record.closing_dte)}
                                        </TableCell>
                                        <TableCell>
                                            {record.funding_status}
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Button variant={'contained'}
                                                    onClick={() => handleConfirmationOpen(record)}>Resolve</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        }
                    }
                    columns={[
                        {
                            name: "order_full_address",
                            label: "Order",
                            options: {
                                sort: true,
                                display: true,
                                download: false,
                            }
                        },{
                            name: "order_full_address",
                            label: "Description",
                            options: {
                                sort: false,
                                display: false,
                                download: true,
                            }
                        },{
                            name: "order_number",
                            label: "Order Number",
                            options: {
                                sort: false,
                                display: false,
                                download: true,
                            }
                        },{
                            name: "companykey",
                            label: "Company Key",
                            options: {
                                sort: false,
                                display: false,
                                download: true,
                            }
                        },{
                            name: "message",
                            label: "Message",
                            options: {
                                sort: true,
                                display: true,
                                download: true,
                            }
                        },{
                            name: "closing_dte",
                            label: "Closing Date",
                            options: {
                                sort: true,
                                display: true,
                                download: true,
                            }
                        },{
                            name: "funding_status",
                            label: "Process Status",
                            options: {
                                sort: true,
                                download: true,
                                display: true,
                            }
                        },{
                            name: "",
                            label: "Action",
                            options: {
                                sort: false,
                                display: true,
                                download: false,
                            }
                        },
                    ]}
                />
            </TableContainer>
            <Dialog
                open={isConfirmationOpen}
                onClose={handleConfirmationClose}
            >
                <DialogTitle>
                    Confirm
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you would like to mark this as resolved? This will remove the transaction from your
                        alert window.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose} disabled={isLoading}>No</Button>
                    <Button onClick={doResolve} autoFocus disabled={isLoading}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default OverdueAlerts;
